import React from "react";
import "./Projects.css";
import SplitScreen from "./SplitScreen";

export default function Projects() {
  
  return (
    <div className="projects">
        <SplitScreen />
    </div>
  );
}
